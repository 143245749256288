<template>
  <v-dialog v-model="showErrors"
            @change="log"
            bg-transition="fade"
            :classes="{
     content: 'card bg-warning-light m-0 p-0'
      }">

    <template v-if="fullCourses.length >0">
      <h2 class="card-header bg-warning text-light">There were some problems enrolling
        {{ plural('course', fullCourses.length, true) }} for {{ plural('student', fullStudents.length, true) }}</h2>
      <template v-if="fullStudents.length > 0">
        <p class="card-body">
          {{ plural('student', fullStudents.length, true) }} were attempting to enroll in
          {{ plural('class', fullCourses.length, true) }} that are now full. You can manage that below.
        </p>
        <div class="list-group list-group-flush list-group-item-action">
          <div v-for="studentEnrollments of fullStudents"
               :key="`fullstudent.${studentEnrollments.student.id}.${studentEnrollments.course_id}`">
            <strong class="pl-2">{{ studentEnrollments.student.name }}</strong>
            <div v-if="!isEmpty(studentEnrollments.enrolledDays)" class="list-group list-group-flush">
              <div class="list-group-item list-group-item-info">
                Please contact community schools if you need to schedule full calendar days.
              </div>
              <div v-for="enrollment of studentEnrollments.enrolledDays"
                   class="list-group-item"
                   :key="'enrolledDay.' + enrollment.course.id + enrollment.student.id">
                <span v-html="enrollment.course.name"/> <small>on</small>
                <strong>{{ dateString(enrollment.course.startDate) }}</strong>

                <span class="btn-group btn-group-sm float-right">
                 <cs-button
                     :title="enrollment.student.name + ' will be removed from cart'"
                     v-if="!isRemovedFromCart(enrollment.student, enrollment.course)"
                     @handle-click="removeFromCart(enrollment.student, enrollment.course)"
                     :loading="$wait.is(waitString('remove', enrollment.student, enrollment.course))"
                     icon-left="clear"
                     type="danger"
                 >
                    Remove from cart
                  </cs-button>
                </span>

              </div>
            </div>
            <div v-if="!isEmpty(studentEnrollments.enrolledCourses)"
                 class="list-group list-group-flush">
              <div class="list-group-item list-group-item-info">
                Some classes may have waiting lists or you can try to sign up for another class.
              </div>
              <div v-for="enrollment of studentEnrollments.enrolledCourses"
                   class="list-group-item"
                   :key="'enrolledDay.' + enrollment.course.id +enrollment.student.id"
              >
                <span v-html="enrollment.course.name"/> <small>{{ dateString(enrollment.course.startDate) }}
                {{ timeString(enrollment.course.startTime) }}</small>
                <span class="btn-group btn-group-sm float-right">
                  <cs-button
                      v-if="hasWaitlist(enrollment.course)  && !onWaitlist(enrollment.student, enrollment.course)"
                      :title="enrollment.student.name + ' will be added to waitlist'"
                      @handle-click="addToWaitlist(enrollment.student, enrollment.course)"
                      :loading="$wait.is(waitString('waitlist.add', enrollment.student, enrollment.course))"
                      icon-left="waitlist"
                      type="warning"
                  >
                    Join Waitlist
                  </cs-button>
                 <cs-button
                     :title="enrollment.student.name + ' will be removed from cart'"
                     v-if="!isRemovedFromCart(enrollment.student, enrollment.course)"
                     @handle-click="removeFromCart(enrollment.student, enrollment.course)"
                     :loading="$wait.is(waitString('remove', enrollment.student, enrollment.course))"
                     icon-left="clear"
                     type="danger"
                 >
                    Remove
                  </cs-button>
                 <cs-button
                     v-if="isNotEmpty(programSlug)"
                     :title="enrollment.student.name + ' will be added to waitlist'"
                     icon-left="sessions"
                     type="success"
                     @handle-click="openSignup(enrollment.course)"
                 >
                    Signup Page
                  </cs-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-if="failedCourses.length >0">
      <h2 class="card-header bg-danger text-light">There were some problems enrolling some courses</h2>
      <div class="card-body">
        <json-view :data="failedCourses"/>
      </div>
    </template>
    <div class="card-footer">
      <cs-button type="info" @handle-click="clickClose" icon-left="cancel">Close</cs-button>
    </div>
  </v-dialog>
</template>

<script>

import { VDialog } from 'vuetensils/src/components'
import { JSONView } from 'vue-json-component'
import { plural } from '@/utilities/stringFormatters'
import { dateString, dateTimeString, timeString } from '@/utilities/dateFormatters'
import { isEmpty, isNotEmpty } from '@/utilities/utilities'
import { addStudentCourseToWaitlist, removeStudentCourseFromCart } from '@/api/courseAPI'

export default {
  name: 'EnrollmentsErrorPortal',
  components: {
    VDialog,
    'json-view': JSONView
  },
  props: {
    fullCourses: {
      type: Array,
      default: () => []
    },
    failedCourses: {
      type: Array,
      default: () => []
    },
    sessions: {
      type: Array,
      default: () => []
    },
    programSlug: {
      type: String,
      required: false,
      default: ''
    },
    registrationId: {
      type: [String, Number],
      required: false,
      default: 0
    },
    year: {
      type: [String, Number],
      required: false,
      default: ''
    },
    memberId: {
      type: [String, Number],
      required: true
    }

  },
  data () {
    return {
      showErrors: true,
      inWaitlist: [],
      removedFromCart: []
    }
  },
  computed: {
    fullDays () {
      return this.fullCourses.filter(enrollment => {
        return !isEmpty(enrollment.course.site_calendar_id)
      })
    },
    fullCoursesInSessions () {
      return this.fullCourses.filter(enrollment => {
        return !isEmpty(enrollment.course.session_id)
      })
    },
    fullStudents () {
      const students = {}
      this.fullCourses.forEach(enrollment => {
        const student = enrollment.student
        const course = enrollment.course
        if (typeof students[student.id] === 'undefined') {
          students[student.id] = {
            student: student,
            courses: [course],
            enrolledCourses: [],
            enrolledDays: []
          }
        }
        students[student.id].courses.push(course)
        if (isEmpty(course.site_calendar_id)) {
          students[student.id].enrolledCourses.push(enrollment)
        } else {
          students[student.id].enrolledDays.push(enrollment)
        }
      })
      return Object.values(students)
    }
  },
  methods: {
    isEmpty,
    isNotEmpty,
    plural,
    dateTimeString,
    dateString,
    timeString,
    hasWaitlist (course) {
      return course.waitlist !== 'none'
    },
    onWaitlist (student, course) {
      return this.inWaitlist.includes(`${student.id}.${course.id}`)
    },
    isRemovedFromCart (student, course) {
      return this.removedFromCart.includes(`${student.id}.${course.id}`)
    },
    addToWaitlist (student, course) {
      this.$wait.start(this.waitString('waitlist.add', student, course))
      addStudentCourseToWaitlist(student, course, this.memberId, this.registrationId, this.programSlug)
        .then(response => {
          const data = response.data
          const result = data.result
          this.$wait.end(this.waitString('waitlist.add', student, course))
          this.$notify({
            group: 'enrollment',
            type: 'success',
            title: course.name,
            text: result.message,
            duration: 2000,
            speed: 1000
          })
          this.inWaitlist.push(`${student.id}.${course.id}`)
          this.removeFromCart(student, course)
          console.log(result)
        })
        .catch(e => {
          this.$wait.end(this.waitString('waitlist.add', student, course))
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: course.name,
            text: `Problem ${student.name} adding to waitlist for ${course.name}`,
            duration: 2000,
            speed: 1000
          })
          console.error(e)
        })
    },
    removeFromCart (student, course) {
      this.$wait.start(this.waitString('remove', student, course))
      removeStudentCourseFromCart(student, course, this.registrationId, this.programSlug)
        .then(response => {
          const data = response.data
          const result = data.result
          this.$wait.end(this.waitString('remove', student, course))
          this.$notify({
            group: 'enrollment',
            type: 'success',
            title: course.name,
            text: result.message,
            duration: 2000,
            speed: 1000
          })
          this.removedFromCart.push(`${student.id}.${course.id}`)
        })
        .catch(e => {
          this.$wait.end(this.waitString('remove', student, course))
          this.$notify({
            group: 'enrollment',
            type: 'error',
            title: course.name,
            text: `Problem ${student.name} adding to waitlist for ${course.name}`,
            duration: 2000,
            speed: 1000
          })
          console.error(e)
        })
    },
    waitString (type, student, course) {
      return `${type}.${student.id}.${course.id}`
    },
    pathFor (course) {
      if (isNotEmpty(course.session_id)) {
        return `/${this.programSlug}/?registration_id=${this.registrationId}&year=${this.year}&select=sessions&session_id=${course.session_id}`
      } else if (isNotEmpty(course.site_calendar_id)) {
        return `/${this.programSlug}/?registration_id=${this.registrationId}&year=${this.year}&select=calendars&calendar_id=${course.site_calendar_id}`
      } else {
        return `/${this.programSlug}/?registration_id=${this.registrationId}&year=${this.year}`
      }
    },
    openSignup (course) {
      window.location.href = this.pathFor(course)
    },
    log: console.log,
    clickClose () {
      console.log('clickClose')
      if (this.inWaitlist.length > 0 || this.removedFromCart.length > 0) {
        window.location.reload()
      } else {
        this.showErrors = false
      }
    }
  }
}
</script>

<style scoped>

</style>
